import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import Header from '@template/header/Header';
import { Content, Footer } from '@template';
import Home from '@pages/home/Home';

function App() {
  return (
    <div className='app'>
      <Layout>
        <Header />
        <Layout>
          {/* <Sidebar /> */}
          <Content>
            <Routes>
              <Route path='/' index={true} element={<Home />} />
              {/* <Route path='/catalog-definition/*' element={<CatalogDefinitionRouter />} /> */}
            </Routes>
          </Content>
        </Layout>
        {/* <Footer /> */}
      </Layout>
    </div>
  );
}

export default App;
