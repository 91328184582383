import AppProviders from './AppProviders';
import App from './App';

import 'antd/dist/reset.css';

function ProvidedApp() {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
}

export default ProvidedApp;
