import { Image, Layout } from 'antd';
// import CodurePng from '@assets/coduture.png';
import CodurePng from '../../../assets/coduture.png';
import style from './Header.style.module.scss';

const Header = () => (
  <Layout.Header className={style['header']}>
    <Image src={CodurePng} preview={false} height={50} />
  </Layout.Header>
);

export default Header;
