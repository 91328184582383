import { FC, PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  logger: {
    log: () => {
      //
    },
    warn: () => {
      //
    },
    error: () => {
      //
    },
  },
});

const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider>{children}</ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppProviders;
