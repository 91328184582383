import PageWrapper from '@components/page-wrapper/PageWrapper';
import style from './Home.module.scss';
const Home = () => {
  return (
    <div className={style['home']}>
      <PageWrapper>
        <b>Coduture</b> solves the unsolvables<span className='blink'>_</span>
        <br />
        <br />
        <p>We are currently reshaping our website.</p>
        <p>
          <a href='mailto:admin@coduture.com'>admin@coduture.com</a>
        </p>
      </PageWrapper>
    </div>
  );
};

export default Home;
